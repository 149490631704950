.share-component {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  
  .share-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .share-options {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    max-height: 80vh; /* Limit the height to 80% of the viewport height */
    overflow-y: auto; /* Add scrolling if there are too many options */
  }
  
  .share-component:hover .share-options {
    display: flex;
  }
  
  .share-options li {
    margin: 5px 0;
  }
  
  .share-options a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .share-options a:hover {
    background-color: #007bff;
    color: white;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .share-component {
      right: 10px;
    }
  
    .share-button {
      padding: 8px 12px;
      font-size: 14px;
    }
  
    .share-options a {
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }
  
  @media (max-width: 480px) {
    .share-component {
      top: auto;
      bottom: 20px;
      right: 10px;
      transform: none;
      max-width: 100%;
      overflow-x: auto;
    }
  
    .share-options {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-height: none;
    }
  
    .share-options li {
      margin: 0 5px;
    }
  }