.chat-container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

input,
textarea {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.model-input {
    width: 100%;
}

.user-prompt-input,
.system-prompt-input {
    width: 100%;
    height: auto;
    min-height: 50px; /* Adjust the min-height as needed */
    padding: 10px;
    box-sizing: border-box;
    resize: vertical; /* Allows the user to resize the textarea vertically */
}

button {
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

h2 {
    margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .chat-container {
        width: 100%;
        padding: 10px;
    }

    .user-prompt-input,
    .system-prompt-input {
        width: 100%;
    }
}

@media (max-width: 480px) {
    input,
    textarea {
        width: 100%;
    }

    button {
        width: 100%;
    }
}
