/* Translation.css */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-container, .translation-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form .form-group, .model-dropdown {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"], input[type="password"], select, .file-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .download-btn {
    margin-top: 20px;
  }
  
  .translation-card {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .translation-card.double-clicked {
    background-color: #ffcccc;
  }
  
  .original-text {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .translated-text {
    background-color: #e0f7fa;
    padding: 10px;
  }
  
  @media (max-width: 600px) {
    .container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5em;
    }
  
    .btn {
      width: 100%;
      padding: 15px;
    }
  
    .download-btn {
      width: 100%;
    }
  }
  